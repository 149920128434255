import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Layout from '../components/Layout';
import ProductGallery from '../components/templates/ProductGallery';

// markup
const PliididPage = () => {
  const data = useStaticQuery(graphql`
  query { 
    allFile(filter: {sourceInstanceName: {eq: "gallery"}, dir: {regex: "/.*\/pliidid$/"}}) {
    nodes {
      dir
      childImageSharp {
        gatsbyImageData(width: 500, quality: 80)
        id
        original {
          src
        }
      }
    }
  }
  }
`);

  const imgs = data
   && data.allFile
  && data.allFile.nodes
  && data.allFile.nodes.map((n) => ({ ...n.childImageSharp }));

  return (
    <Layout pageTitle="Pliidid">
      <ProductGallery
        imgs={imgs}
        listOfThings={[
          'Soemüüri ehitus (tellis, pott)',
          'Puupliidi ehitus (tellis, pott)',
          'Pliitide remont ja hooldus',
        ]}
      />
    </Layout>
  );
};

export default PliididPage;
